<template>
  <div class="container">
    <table-page dataName="registers"
                :search="search"
                ref="table"
                :tabList="tabList"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="getPubliRecords">
      <template slot="button">
        <FileExport :params="exportParams">导出数据</FileExport>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="wx_mini_openid"
                       show-overflow-tooltip
                       label="OPPENID">
      </el-table-column>
      <el-table-column prop="submited_at"
                       show-overflow-tooltip
                       label="提交时间">
      </el-table-column>
      <el-table-column prop="status"
                       show-overflow-tooltip
                       label="派发状态">
      </el-table-column>
      <el-table-column prop="normal_count"
                       show-overflow-tooltip
                       label="派发成功">
      </el-table-column>
      <el-table-column prop="fail_count"
                       show-overflow-tooltip
                       label="派发失败">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$refs.record.showModal(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </table-page>
    <Record ref="record"
            @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import FileExport from '@/components/FileExport'
import Record from './components/Record'
import { getPubliRecords, delWhite } from '@/api/market'
export default {
  name: 'PublicRecords',
  data () {
    return {
      exportParams: null,
      exportUrl: '',
      getPubliRecords,
      search: [{
        type: 'input',
        name: '手机号码',
        placeholder: '请输入手机号码',
        key: 'tel',
        value: ''
      }],
      inputList: [{
        name: '手机号码',
        placeholder: '请输入手机号码',
        key: 'tel',
        value: ''
      }],
      searchParams: {},
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NORMAL',
        label: '已派发'
      }, {
        value: 'PENDING',
        label: '未派发'
      }, {
        value: 'FAIL',
        label: '派发失败'
      }]
    }
  },
  components: {
    TablePage,
    Record,
    FileExport
  },
  methods: {
    downloadWhite () {
      window.open('https://h.postpage.cn/data/envelope/%E6%96%B0%E5%85%AC%E5%BC%80%E6%B4%BE%E5%88%B8%E7%99%BD%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx', '_blank')
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    },
    addStore () {
      this.$router.push({
        name: 'SetStore'
      })
    },
    setStore (id) {
      this.$router.push({
        name: 'SetStore',
        query: {
          id: id
        }
      })
    },
    delWhite (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delWhite({
          activity_id: this.$route.query.id,
          black_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
      this.exportParams = e.export
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
