<template>
  <el-dialog title="派发详情"
             :close-on-click-modal='false'
             width="60%"
             :visible.sync="dialogVisible">
    <div>
      <table-page dataName="list"
                  init
                  ref="table"
                  :params="{register_id:register_id}"
                  :request="getPubliRecord">
        <el-table-column prop="coupon_stock_id"
                         show-overflow-tooltip
                         label="批次号">
        </el-table-column>
        <el-table-column prop="coupon_name"
                         show-overflow-tooltip
                         label="代金券名称">
        </el-table-column>
        <el-table-column prop="wx_coupon_id"
                         show-overflow-tooltip
                         label="代金券id">
        </el-table-column>
        <el-table-column prop="status"
                         show-overflow-tooltip
                         label="领取状态">
        </el-table-column>
        <el-table-column prop="err_msg"
                         show-overflow-tooltip
                         label="失败原因">
        </el-table-column>
        <el-table-column prop="created_at"
                         show-overflow-tooltip
                         label="核销状态">
          <template slot-scope="scope">
            <span v-if="scope.row.wx_status === 'SENDED'">
              <el-badge is-dot
                        type="warning"
                        class="mr-15"></el-badge>未使用
            </span>
            <span v-else-if="scope.row.wx_status === 'EXPIRED'">
              <el-badge is-dot
                        type="warning"
                        class="mr-15"></el-badge>未使用
            </span>
            <span v-else-if="scope.row.wx_status === 'USED'">
              <el-badge is-dot
                        type="success"
                        class="mr-15"></el-badge>已使用
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="consume_at"
                         show-overflow-tooltip
                         label="核销时间">
        </el-table-column>
      </table-page>
    </div>
    <span slot="footer"
          class="dialog-footer">
    </span>
  </el-dialog>
</template>

<script>
import { getPubliRecord } from '@/api/market'
import TablePage from '@/components/TablePage'
export default {
  data () {
    return {
      getPubliRecord,
      form: {
        activity_id: '',
        id: '',
        tel: ''
      },
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false,
      register_id: ''
    }
  },
  components: {
    TablePage
  },
  methods: {
    showModal (formData) {
      this.register_id = formData.register_id
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
